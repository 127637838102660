var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: "Datafiles", items: _vm.computedItems },
      }),
      _vm.isPageLoading
        ? _c("div", [
            _c(
              "div",
              { staticClass: "row justify-content-center mt-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.isPageLoading && _vm.initialPageDataFetchingError
        ? _c("div", [_c("DataFetchingErrorWidget")], 1)
        : _vm._e(),
      !_vm.isPageLoading
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "row card-body" }, [
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("datafiles.importTypes.label")) +
                              " "
                          ),
                        ]),
                        _c("multiselect", {
                          attrs: {
                            "deselect-label": "Can't remove this value",
                            "track-by": "value",
                            label: "label",
                            options: _vm.allImportTypes,
                            searchable: false,
                            "close-on-select": true,
                            allowEmpty: false,
                            placeholder: "DE Pick a value",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.changeDataOneOfTheFilters()
                            },
                          },
                          model: {
                            value: _vm.selectedImportType,
                            callback: function ($$v) {
                              _vm.selectedImportType = $$v
                            },
                            expression: "selectedImportType",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-3" }, [
                    _vm.loggedInUser.accessLevel ===
                    _vm.accessLevelEnum.SUPER_ADMIN
                      ? _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("datafiles.ownedCompany.label")
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("multiselect", {
                              attrs: {
                                options: _vm.allCompanies,
                                "close-on-select": true,
                                "track-by": "name",
                                searchable: true,
                                label: "name",
                                placeholder: _vm.$t(
                                  "datafiles.ownedCompany.placeholder"
                                ),
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.changeDataOneOfTheFilters()
                                },
                              },
                              model: {
                                value: _vm.selectedCompany,
                                callback: function ($$v) {
                                  _vm.selectedCompany = $$v
                                },
                                expression: "selectedCompany",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-md-6 text-right" },
                    [
                      _c(
                        "b-button-group",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                size: "sm",
                                variant:
                                  this.dataDisplayLayout ===
                                  this.layoutTypesEnum.GRID
                                    ? "light"
                                    : "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changeDatafileView(
                                    _vm.layoutTypesEnum.LIST
                                  )
                                },
                              },
                            },
                            [_c("i", { staticClass: "bx bx-list-ul" })]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                size: "sm",
                                variant:
                                  this.dataDisplayLayout ===
                                  this.layoutTypesEnum.LIST
                                    ? "light"
                                    : "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changeDatafileView(
                                    _vm.layoutTypesEnum.GRID
                                  )
                                },
                              },
                            },
                            [_c("i", { staticClass: "bx bx-grid-alt" })]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      !_vm.isPageLoading && _vm.isDatafileSectionLoading
        ? _c("div", [
            _c(
              "div",
              { staticClass: "row justify-content-center mt-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      this.dataDisplayLayout === this.layoutTypesEnum.GRID &&
      !_vm.isPageLoading &&
      !_vm.isDatafileSectionLoading &&
      !_vm.enableNoDatafilesForSelectedFilters
        ? _c(
            "div",
            { staticClass: "row" },
            _vm._l(
              this.datafilesToDisplay,
              function (datafile, allDatafileIndex) {
                return _c(
                  "div",
                  { key: allDatafileIndex, staticClass: "col-md-4" },
                  [
                    _c("ProjectCardGrid", {
                      attrs: {
                        project: datafile,
                        loggedInUser: _vm.loggedInUser,
                      },
                      on: {
                        DeleteDetafile: _vm.deleteDatafile,
                        ChangeDetafileStatus: _vm.changeDatafileStatus,
                        ChangeDetafileOwnership: _vm.changeDetafileOwnership,
                        RemoveDetafile: _vm.removeDatafile,
                        ResetDatafile: _vm.resetDatafile,
                      },
                    }),
                  ],
                  1
                )
              }
            ),
            0
          )
        : _vm._e(),
      this.dataDisplayLayout === this.layoutTypesEnum.LIST &&
      !_vm.isPageLoading &&
      !_vm.isDatafileSectionLoading &&
      !_vm.enableNoDatafilesForSelectedFilters
        ? _c(
            "div",
            { staticClass: "row" },
            _vm._l(
              this.datafilesToDisplay,
              function (datafile, allDatafileIndex) {
                return _c(
                  "div",
                  { key: allDatafileIndex, staticClass: "col-md-12" },
                  [
                    _c("ProjectCardList", {
                      attrs: {
                        project: datafile,
                        loggedInUser: _vm.loggedInUser,
                      },
                      on: {
                        DeleteDetafile: _vm.deleteDatafile,
                        ChangeDetafileStatus: _vm.changeDatafileStatus,
                        ChangeDetafileOwnership: _vm.changeDetafileOwnership,
                        RemoveDetafile: _vm.removeDatafile,
                        ResetDatafile: _vm.resetDatafile,
                      },
                    }),
                  ],
                  1
                )
              }
            ),
            0
          )
        : _vm._e(),
      !_vm.isPageLoading &&
      !_vm.isDatafileSectionLoading &&
      _vm.enableNoDatafilesForSelectedFilters
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12 text-center" }, [
              _c("img", {
                staticClass: "img-fluid mb-4 mt-5",
                staticStyle: { width: "60px" },
                attrs: {
                  src: require("@/assets/images/information.png"),
                  alt: "",
                },
              }),
            ]),
            _c(
              "div",
              { staticClass: "col-12 text-center" },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("datafiles.noDatafiles.title")) + " "
                ),
                _c("br"),
                _c(
                  "b-button",
                  {
                    staticClass: "mt-3",
                    attrs: { variant: "success" },
                    on: {
                      click: function ($event) {
                        _vm.selectedImportType.value ===
                          _vm.importTypesEnum.LIVE ||
                        _vm.selectedImportType.value ===
                          _vm.importTypesEnum.DRAFT
                          ? _vm.clickRedirectToDefineDatafile()
                          : _vm.clickRedirectToUpload()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "bx bx-upload font-size-18 mr-2" }),
                    _vm._v(
                      _vm._s(_vm.$t("datafiles.noDatafiles.startNowButton")) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.isPageLoading &&
      !_vm.isDatafileSectionLoading &&
      _vm.fetchingDatafilesError
        ? _c("div", [_c("DataFetchingErrorWidget")], 1)
        : _vm._e(),
      !_vm.isPageLoading &&
      !_vm.isDatafileSectionLoading &&
      !_vm.enableNoDatafilesForSelectedFilters
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("b-pagination", {
                  attrs: {
                    pills: "",
                    "total-rows": _vm.totalNumberOfPages,
                    "per-page": _vm.numberOfDatafilesPerPage,
                    align: "right",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.changePage()
                    },
                  },
                  model: {
                    value: _vm.currentPage,
                    callback: function ($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.showDatafileDeleteModal === true
        ? _c("DeleteDatafileModal", {
            attrs: {
              visible: _vm.showDatafileDeleteModal,
              accesslevel: _vm.loggedInUser.accessLevel,
              datafileObject: this.datafileToDelete,
            },
            on: {
              close: function ($event) {
                _vm.showDatafileDeleteModal = false
              },
              reload: function ($event) {
                return _vm.reloadPage()
              },
            },
          })
        : _vm._e(),
      _vm.showDatafileChangeStatusModal === true
        ? _c("ChangeDatafileStatusModal", {
            attrs: {
              visible: _vm.showDatafileChangeStatusModal,
              datafileObject: this.datafileToChangeStatus,
            },
            on: {
              close: function ($event) {
                _vm.showDatafileChangeStatusModal = false
              },
              reload: function ($event) {
                return _vm.reloadPage()
              },
            },
          })
        : _vm._e(),
      _vm.showChangeDatafileOwnershipModal === true
        ? _c("ChangeOwnershipOfDatafileModal", {
            attrs: {
              visible: _vm.showChangeDatafileOwnershipModal,
              datafileObject: this.datafileToChangeOwnership,
            },
            on: {
              close: function ($event) {
                _vm.showChangeDatafileOwnershipModal = false
              },
              reload: function ($event) {
                return _vm.reloadPage()
              },
            },
          })
        : _vm._e(),
      _vm.showDatafileRemoveModal === true
        ? _c("RemoveDatafileModal", {
            attrs: {
              visible: _vm.showDatafileRemoveModal,
              datafileObject: this.datafileToRemove,
            },
            on: {
              close: function ($event) {
                _vm.showDatafileRemoveModal = false
              },
              reload: function ($event) {
                return _vm.reloadPage()
              },
            },
          })
        : _vm._e(),
      _vm.showDatafilResetModal === true
        ? _c("ResetDatafileModal", {
            attrs: {
              visible: _vm.showDatafilResetModal,
              accesslevel: _vm.loggedInUser.accessLevel,
              datafileObject: this.datafileToReset,
            },
            on: {
              close: function ($event) {
                _vm.showDatafilResetModal = false
              },
              reload: function ($event) {
                return _vm.reloadPage()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }