<script>
import AuthService from "@/api/services/auth.service";
import CompanyService from "@/api/services/company.service.js";
import UserService from "@/api/services/user.service";
import appConfig from "@/app.config";
import ChangeDatafileStatusModal from "@/redem/datafiles/components/modals/change-datafile-status-modal.vue";
import DeleteDatafileModal from "@/redem/datafiles/components/modals/delete-datafile-modal.vue";
import RemoveDatafileModal from "@/redem/datafiles/components/modals/remove-datafile-modal.vue";
import Layout from "@/router/layouts/main";
import PageHeader from "@/shared/components/page-header";
import DataFetchingErrorWidget from "@/shared/components/widgets/data-fetching-error";
import AccessLevels from "@/shared/enums/aceessLevels";
import importTypes from "@/shared/enums/importTypes";
import LayoutTypes from "@/shared/enums/layoutTypes";
import Multiselect from "vue-multiselect";
import ChangeOwnershipOfDatafileModal from "../components/modals/change-datafile-ownership.vue";
import ResetDatafileModal from "../components/modals/reset-datafile-modal.vue";
import ProjectCardGrid from "../components/widgets/project-cards/project-card-grid.vue";
import ProjectCardList from "../components/widgets/project-cards/project-card-list.vue";

/**
 * Datafiles
 */
export default {
  page: {
    title: "Datafiles",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    ProjectCardGrid,
    ProjectCardList,
    DataFetchingErrorWidget,
    DeleteDatafileModal,
    ChangeDatafileStatusModal,
    ChangeOwnershipOfDatafileModal,
    RemoveDatafileModal,
    ResetDatafileModal
  },
  data() {
    return {
      accessLevelEnum: AccessLevels,
      layoutTypesEnum: LayoutTypes,
      importTypesEnum: importTypes,
      loggedInUser: null,
      datafilesToDisplay: null,
      /***** Pagination Infomation *****/
      currentPage: 1,
      numberOfDatafilesPerPage: 9,
      totalNumberOfPages: null,
      /****** Filter Options ******/
      selectedImportType: {label : this.$i18n.t("datafiles.importTypes.all"), value: importTypes.ALL},
      selectedCompany: null,
      allCompanies: null,
      allImportTypes: [
        {label : this.$i18n.t("datafiles.importTypes.all"), value: importTypes.ALL},
        {label : this.$i18n.t("datafiles.importTypes.live"), value: importTypes.LIVE},
        {label : this.$i18n.t("datafiles.importTypes.manual"), value: importTypes.XLXS},
        {label : this.$i18n.t("datafiles.importTypes.draft"), value: importTypes.DRAFT}
      ],
      /***** Display options *****/
      dataDisplayLayout: LayoutTypes.GRID,
      isPageLoading: false,
      isDatafileSectionLoading: false,
      isDisplayNoDatafiles: false,
      initialPageDataFetchingError: false,
      fetchingDatafilesError: false,
      enableNoDatafilesForSelectedFilters: false,
      /**** Modal Related ***/
      datafileToDelete: null,
      datafileToChangeStatus: null,
      datafileToChangeOwnership: null,
      datafileToReset: null,
      showDatafileDeleteModal: false,
      showDatafileChangeStatusModal: false,
      showChangeDatafileOwnershipModal: false,
      showDatafileRemoveModal: false,
      showDatafilResetModal: false,
    };
  },
  computed: {
    computedItems() {
      var items = [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Datafile",
          active: true
        }
      ];
      return items;
    }
  },
  async created() {
    await this.setInitialPageSettings()
  },
  methods: {
    changeDatafileView(layoutType) {
      this.dataDisplayLayout = layoutType
      window.localStorage.setItem("datasetView", layoutType);
    },
    async setInitialPageSettings() {
      try {
        // 1. Enable Page Loading
        this.isPageLoading = true

        // 2. Set data display layout
        this.dataDisplayLayout = localStorage.getItem("datasetView") ? localStorage.getItem("datasetView") : this.layoutTypesEnum.GRID

        // 3. Get Logged In user
        this.loggedInUser = await AuthService.getLoggedInUser();

        // 4. Fetch All Companies if Logged In user is Super Admin
        if(this.loggedInUser.accessLevel === this.accessLevelEnum.SUPER_ADMIN) {
          this.allCompanies = await CompanyService.getAllCompnies();
        }

        // 5. Set selected Company for Non Super Admin Users
        if(this.loggedInUser.accessLevel !== this.accessLevelEnum.SUPER_ADMIN) {
          this.selectedCompany = this.loggedInUser.company
        }

        // 6. Fetch Data files for Initial page
        await this.fetchDatafiles(
          this.selectedImportType.value === 'ALL' ? null : this.selectedImportType.value, 
          this.selectedCompany ? this.selectedCompany.id : null,
          this.currentPage
        )

        // 7. Disable Page Loading
        this.isPageLoading = false
      } catch(error) {
        this.isPageLoading = false
        this.initialPageDataFetchingError = true
      }
    },
    async fetchDatafiles(importType, company, requestedPage) {
      try {
        // 1. Enable data files section loading
        this.isDatafileSectionLoading = true

        // 2. Make variables into default values
        this.datafilesToDisplay = null
        this.enableNoDatafilesForSelectedFilters = false

        // 3. Fetch Datafiles to Display according to user access Level
        const response = await UserService.getDatafilesForLoggedInUser(
          importType,
          company,
          requestedPage,
          this.numberOfDatafilesPerPage
        );
        
        // 4 set values to local variables
        this.datafilesToDisplay = response.datafiles
        this.currentPage = response.currentPage
        this.totalNumberOfPages = response.totalNumberOfDatafiles

        // 5 Enable info is not datafiles for selected fliters
        if(this.totalNumberOfPages === 0) {
          this.enableNoDatafilesForSelectedFilters = true
        }
        
        // 6. Disable data files section loading
        this.isDatafileSectionLoading = false
        
      } catch(error) {
        this.isDatafileSectionLoading = false
        this.fetchingDatafilesError = true
      }
    },
    async changePage() {
      await this.fetchDatafiles(
        this.selectedImportType.value === 'ALL' ? null : this.selectedImportType.value, 
        this.selectedCompany ? this.selectedCompany.id : null, 
        this.currentPage
      )
    },
    async changeDataOneOfTheFilters(){
      await this.fetchDatafiles(
        this.selectedImportType.value === 'ALL' ? null : this.selectedImportType.value, 
        this.selectedCompany ? this.selectedCompany.id : null, 
        this.currentPage
      )
    },
    reloadPage() {
      window.location.reload();
    },
    deleteDatafile(datafile) {
      this.datafileToDelete = datafile;
      this.showDatafileDeleteModal = true;
    },
    changeDatafileStatus(datafile) {
      this.datafileToChangeStatus = datafile;
      this.showDatafileChangeStatusModal = true;
    },
    changeDetafileOwnership(datafile) {
      this.datafileToChangeOwnership = datafile;
      this.showChangeDatafileOwnershipModal = true;
    },
    removeDatafile(datafile) {
      this.datafileToRemove = datafile;
      this.showDatafileRemoveModal = true;
    },
    resetDatafile(datafile) {
      this.datafileToReset = datafile;
      this.showDatafilResetModal = true;
    },
    clickRedirectToUpload() {
      this.$router.push("./upload-dataset/step1");
    },
    clickRedirectToDefineDatafile() {
      this.$router.push("./live-import");
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader title="Datafiles" :items="computedItems" />

    <!-- Main Page Loader-->
    <div v-if="isPageLoading">
      <div class="row justify-content-center mt-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>

    <!-- Main Data fetching Error -->
    <div v-if="!isPageLoading && initialPageDataFetchingError">
      <DataFetchingErrorWidget/>
    </div>

    <!-- Filter & Display Options -->
    <div class="row" v-if="!isPageLoading">
      <div class="col-md-12">
        <div class="card">
          <div class="row card-body">
            <!-- Import Type -->
            <div class="col-md-3">
              <div class="form-group">
                <label> {{ $t("datafiles.importTypes.label")}} </label> 
                <multiselect
                  v-model="selectedImportType"
                  deselect-label="Can't remove this value"
                  track-by="value"
                  label="label"
                  :options="allImportTypes"
                  :searchable="false"
                  :close-on-select="true"
                  :allowEmpty="false"
                  placeholder="DE Pick a value"
                  @input="changeDataOneOfTheFilters()"
                >
                </multiselect>
              </div>
            </div>
            <!-- Owned Company -->
            <div class="col-md-3">
              <div class="form-group" v-if="loggedInUser.accessLevel === accessLevelEnum.SUPER_ADMIN">
                <label> {{ $t("datafiles.ownedCompany.label")}} </label> 
                <multiselect
                  v-model="selectedCompany"
                  :options="allCompanies"
                  :close-on-select="true"
                  track-by="name"
                  :searchable="true"
                  label="name"
                  :placeholder="$t('datafiles.ownedCompany.placeholder')"
                  @input="changeDataOneOfTheFilters()"
                >
                </multiselect>
              </div>
            </div>
            <!-- View -->
            <div class="col-md-6 text-right">
              <b-button-group>
                <b-button size="sm" :variant="this.dataDisplayLayout === this.layoutTypesEnum.GRID ? 'light' : 'primary'" @click="changeDatafileView(layoutTypesEnum.LIST)">
                  <i class="bx bx-list-ul"></i>
                </b-button>
                <b-button size="sm" :variant="this.dataDisplayLayout === this.layoutTypesEnum.LIST ? 'light' : 'primary'" @click="changeDatafileView(layoutTypesEnum.GRID)">
                  <i class="bx bx-grid-alt"></i>
                </b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Datafiles Section Loader -->
    <div v-if="!isPageLoading && isDatafileSectionLoading">
      <div class="row justify-content-center mt-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>

    <!-- Grid Layout for display Datafiles -->
    <div class="row" v-if="this.dataDisplayLayout === this.layoutTypesEnum.GRID && !isPageLoading && !isDatafileSectionLoading && !enableNoDatafilesForSelectedFilters">
      <div
        class="col-md-4"
        v-for="(datafile, allDatafileIndex) in this.datafilesToDisplay"
        :key="allDatafileIndex"
      >
        <ProjectCardGrid
          :project="datafile"
          :loggedInUser="loggedInUser"
          @DeleteDetafile="deleteDatafile"
          @ChangeDetafileStatus="changeDatafileStatus"
          @ChangeDetafileOwnership="changeDetafileOwnership"
          @RemoveDetafile="removeDatafile"
          @ResetDatafile="resetDatafile"
        />
      </div>
    </div>

    <!-- List Layout for display Datafiles -->
    <div class="row" v-if="this.dataDisplayLayout === this.layoutTypesEnum.LIST && !isPageLoading && !isDatafileSectionLoading && !enableNoDatafilesForSelectedFilters">
      <div
        class="col-md-12"
        v-for="(datafile, allDatafileIndex) in this.datafilesToDisplay"
        :key="allDatafileIndex"
      >
        <ProjectCardList
          :project="datafile"
          :loggedInUser="loggedInUser"
          @DeleteDetafile="deleteDatafile"
          @ChangeDetafileStatus="changeDatafileStatus"
          @ChangeDetafileOwnership="changeDetafileOwnership"
          @RemoveDetafile="removeDatafile"
          @ResetDatafile="resetDatafile"
        />
      </div>
    </div>

    <!-- No Datafiles to display for Filter selection -->
    <div class="row" v-if="!isPageLoading && !isDatafileSectionLoading && enableNoDatafilesForSelectedFilters">
      <div class="col-md-12 text-center">
        <img
          src="@/assets/images/information.png"
          alt
          class="img-fluid mb-4 mt-5"
          style="width: 60px"
        />
      </div>
      <div class="col-12 text-center">
          {{ $t("datafiles.noDatafiles.title") }}
          <br />
          <b-button
            variant="success"
            @click="(selectedImportType.value === importTypesEnum.LIVE || selectedImportType.value === importTypesEnum.DRAFT) ? clickRedirectToDefineDatafile() : clickRedirectToUpload()"
            class="mt-3"
          >
            <i class="bx bx-upload font-size-18 mr-2"></i
            >{{ $t("datafiles.noDatafiles.startNowButton") }}
          </b-button>
        </div>
    </div>

    <!-- Data files fetching Error -->
    <div v-if="!isPageLoading && !isDatafileSectionLoading && fetchingDatafilesError">
      <DataFetchingErrorWidget/>
    </div>

    <!-- Pagination Components -->
    <div class="row" v-if="!isPageLoading && !isDatafileSectionLoading && !enableNoDatafilesForSelectedFilters">
      <div class="col-md-12">
        <b-pagination
          v-model="currentPage"
          pills
          :total-rows="totalNumberOfPages"
          :per-page="numberOfDatafilesPerPage"
          align="right"
          @input="changePage()"
        ></b-pagination>
      </div>
    </div>

    <!-- Delete Datafile Modal -->
    <DeleteDatafileModal
      v-if="showDatafileDeleteModal === true"
      :visible="showDatafileDeleteModal"
      :accesslevel="loggedInUser.accessLevel"
      :datafileObject="this.datafileToDelete"
      @close="showDatafileDeleteModal = false"
      @reload="reloadPage()"
    />

    <!-- Change Datafile Status Modal -->
    <ChangeDatafileStatusModal
      v-if="showDatafileChangeStatusModal === true"
      :visible="showDatafileChangeStatusModal"
      :datafileObject="this.datafileToChangeStatus"
      @close="showDatafileChangeStatusModal = false"
      @reload="reloadPage()"
    />

    <!-- Chnage Ownership of Datafile Modal -->
    <ChangeOwnershipOfDatafileModal
      v-if="showChangeDatafileOwnershipModal === true"
      :visible="showChangeDatafileOwnershipModal"
      :datafileObject="this.datafileToChangeOwnership"
      @close="showChangeDatafileOwnershipModal = false"
      @reload="reloadPage()"
    />

    <!-- Remove Datafile Modal -->
    <RemoveDatafileModal
      v-if="showDatafileRemoveModal === true"
      :visible="showDatafileRemoveModal"
      :datafileObject="this.datafileToRemove"
      @close="showDatafileRemoveModal = false"
      @reload="reloadPage()"
    />

    <!-- Reset Datafile Modal -->
    <ResetDatafileModal
      v-if="showDatafilResetModal === true"
      :visible="showDatafilResetModal"
      :accesslevel="loggedInUser.accessLevel"
      :datafileObject="this.datafileToReset"
      @close="showDatafilResetModal = false"
      @reload="reloadPage()"
    />

  </Layout>
</template>
