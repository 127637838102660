var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("h5", [_vm._v(" Change Ownership of the Datafile ")]),
      _c("hr"),
      !_vm.isLoading && !_vm.transferDatafileOwnershipSuccess
        ? _c("div", { staticClass: "mt-3" }, [
            _c("div", { staticClass: "row mt-3" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c("span", { staticClass: "mr-2" }, [
                  _c("label", [_vm._v(" Datafile Title: ")]),
                ]),
                _vm._v(_vm._s(_vm.datafile.name) + " "),
              ]),
              _c("div", { staticClass: "col-md-12" }, [
                _c("span", { staticClass: "mr-2" }, [
                  _c("label", [_vm._v(" Company: ")]),
                ]),
                _vm._v(_vm._s(_vm.datafile.company.name) + " "),
              ]),
              _c("div", { staticClass: "col-md-12" }, [
                _c("span", { staticClass: "mr-2" }, [
                  _c("label", [_vm._v(" Owner: ")]),
                ]),
                _vm._v(
                  _vm._s(_vm.datafile.owner.name.fist) +
                    " " +
                    _vm._s(_vm.datafile.owner.name.last) +
                    " "
                ),
                _vm.datafile.owner.name.fist || _vm.datafile.owner.name.last
                  ? _c("span", [_vm._v(" - ")])
                  : _vm._e(),
                _vm._v(" " + _vm._s(_vm.datafile.owner.email) + " "),
              ]),
            ]),
            _c("hr"),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c(
                    "b-alert",
                    {
                      staticClass: "col-md-12",
                      attrs: {
                        show: _vm.transferDatafileOwnershipFails,
                        variant: "danger",
                        dismissible: "",
                      },
                    },
                    [_vm._v("Datafile Ownership Transfer Failed")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row mt-3" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c("label", [_vm._v(" Select Company: ")]),
                  _c("multiselect", {
                    attrs: {
                      options: _vm.allCompanies,
                      placeholder: _vm.$t(
                        "uploadProcessStep2.form.general.selectColumnType.placeholder"
                      ),
                      "track-by": "name",
                      searchable: true,
                      label: "name",
                    },
                    on: { input: _vm.selectCompany },
                    model: {
                      value: _vm.selectedCompany,
                      callback: function ($$v) {
                        _vm.selectedCompany = $$v
                      },
                      expression: "selectedCompany",
                    },
                  }),
                ],
                1
              ),
            ]),
            this.selectedCompany
              ? _c("div", { staticClass: "row mt-3" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("label", [_vm._v(" Select New Owner: ")]),
                      !_vm.isFetchingComapanyUsers
                        ? _c("multiselect", {
                            attrs: {
                              options: _vm.allUsersOfSelectedCompany,
                              placeholder: _vm.$t(
                                "uploadProcessStep2.form.general.selectColumnType.placeholder"
                              ),
                              "track-by": "id",
                              searchable: true,
                              label: "name",
                              "custom-label": _vm.nameWithEmail,
                              "show-labels": false,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "singleLabel",
                                  fn: function ({ option }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(option.name.first) +
                                          " " +
                                          _vm._s(option.name.last) +
                                          " "
                                      ),
                                      option.name.first || option.name.last
                                        ? _c("span", [_vm._v(" - ")])
                                        : _vm._e(),
                                      _vm._v(" " + _vm._s(option.email) + " "),
                                    ]
                                  },
                                },
                                {
                                  key: "option",
                                  fn: function ({ option }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(option.name.first) +
                                          " " +
                                          _vm._s(option.name.last) +
                                          " "
                                      ),
                                      option.name.first || option.name.last
                                        ? _c("span", [_vm._v(" - ")])
                                        : _vm._e(),
                                      _vm._v(" " + _vm._s(option.email) + " "),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3561126774
                            ),
                            model: {
                              value: _vm.selectedUser,
                              callback: function ($$v) {
                                _vm.selectedUser = $$v
                              },
                              expression: "selectedUser",
                            },
                          })
                        : _vm._e(),
                      _vm.isFetchingComapanyUsers
                        ? _c("i", {
                            staticClass: "bx bx-loader-circle bx-spin ml-2",
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            this.selectedCompany
              ? _c("div", { staticClass: "row mt-3" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c(
                      "div",
                      { staticClass: "custom-control custom-checkbox" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.changeWalletAndTransactions,
                              expression: "changeWalletAndTransactions",
                            },
                          ],
                          staticClass: "custom-control-input",
                          attrs: {
                            type: "checkbox",
                            id: "CleanDatafileCheckbox",
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.changeWalletAndTransactions
                            )
                              ? _vm._i(_vm.changeWalletAndTransactions, null) >
                                -1
                              : _vm.changeWalletAndTransactions,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.changeWalletAndTransactions,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.changeWalletAndTransactions =
                                      $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.changeWalletAndTransactions = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.changeWalletAndTransactions = $$c
                              }
                            },
                          },
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label",
                            attrs: { for: "CleanDatafileCheckbox" },
                          },
                          [
                            _c("span", { staticClass: "font-weight-normal" }, [
                              _vm._v(" Change Wallets & Transactions "),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "row mt-5 mb-2" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "warning" },
                      on: { click: _vm.clickChangeOwnership },
                    },
                    [_vm._v(" Change Ownership ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-2",
                      attrs: { variant: "light" },
                      on: { click: _vm.clickCloseButton },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("datafiles.deleteDatafileModal.cancelButton")
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm.transferDatafileOwnershipSuccess && !_vm.isLoading
        ? _c("div", { staticClass: "row mt-3 text-center" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("h5", { staticClass: "text-success" }, [
                _vm._v("Successfully Transfer Datafile"),
              ]),
              _c("p", [
                _vm._v(
                  "Datafile owenership is successfully transfer to new owner"
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "col-md-12 mt-3" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "light" },
                    on: { click: _vm.clickSuccessCloseButton },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("datafiles.deleteDatafileModal.cancelButton")
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.isLoading
        ? _c("div", { staticClass: "row justify-content-center" }, [
            _c(
              "div",
              { staticClass: "col-6 text-center pt-5 pb-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }