<script>
import CompanyService from "@/api/services/company.service.js";
import DatafileService from "@/api/services/datafile.service.js";
import Multiselect from "vue-multiselect";
export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    datafileObject: Object
  },
  components: {
    Multiselect
  },
  data() {
    return {
      datafile: null,
      allCompanies: null,
      selectedCompany: null,
      allUsersOfSelectedCompany: null,
      selectedUser: null,
      changeWalletAndTransactions: false,
      /********* Operational Variables *******/
      isLoading: false,
      isFetchingComapanyUsers: false,
      fetchingCompaniesError: false,
      fetchingCompanyUsersError: false,
      transferDatafileOwnershipSuccess: false,
      transferDatafileOwnershipFails: false
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  async created() {
    this.datafile = this.datafileObject;
    await this.getAllCompanies();
  },
  methods: {
    async getAllCompanies() {
      this.isLoading = true;
      try {
        const allCompanies = await CompanyService.getAllCompnies();
        if (allCompanies.length > 0) {
          this.allCompanies = allCompanies;
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
        this.fetchingCompaniesError = true;
        this.isLoading = false;
      }
    },
    async selectCompany() {
      this.isFetchingComapanyUsers = true;
      try {
        const allCompanyUsers = await CompanyService.getUsersOfCompany(
          this.selectedCompany.id
        );
        if (allCompanyUsers.length > 0) {
          this.allUsersOfSelectedCompany = allCompanyUsers;
          this.isFetchingComapanyUsers = false;
        }
      } catch (error) {
        console.log(error);
        this.fetchingCompanyUsersError = true;
        this.isFetchingComapanyUsers = false;
        this.isLoading = false;
      }
    },
    async clickChangeOwnership() {
      this.isLoading = true;
      const res = await DatafileService.transferDatafileOwnership(
        this.datafile.id,
        this.selectedCompany.id,
        this.selectedUser.id,
        this.changeWalletAndTransactions
      );
      if (res.status === "SUCCESS") {
        this.transferDatafileOwnershipSuccess = true;
        this.isLoading = false;
      } else {
        this.transferDatafileOwnershipFails = true;
        this.isLoading = false;
      }
    },
    clickSuccessCloseButton() {
      this.$emit("reload");
    },
    clickCloseButton() {
      this.$emit("close");
    },
    nameWithEmail({ name, email }) {
      return `${name.first} ${name.last} - ${email}`;
    }
  }
};
</script>
<template>
  <!-- Delete Dataset Model -->
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <!-- Model Title -->
    <h5>
      Change Ownership of the Datafile
    </h5>
    <hr />
    <!-- Form -->
    <div class="mt-3" v-if="!isLoading && !transferDatafileOwnershipSuccess">
      <!-- Current Datafile -->
      <div class="row mt-3">
        <div class="col-md-12">
          <span class="mr-2"> <label> Datafile Title: </label></span
          >{{ datafile.name }}
        </div>
        <div class="col-md-12">
          <span class="mr-2"> <label> Company: </label></span
          >{{ datafile.company.name }}
        </div>
        <div class="col-md-12">
          <span class="mr-2"> <label> Owner: </label></span
          >{{ datafile.owner.name.fist }} {{ datafile.owner.name.last }}
          <span v-if="datafile.owner.name.fist || datafile.owner.name.last">
            -
          </span>
          {{ datafile.owner.email }}
        </div>
      </div>
      <hr />
      <!-- Error Msg -->
      <div class="row">
        <div class="col-md-12">
          <b-alert
            :show="transferDatafileOwnershipFails"
            class="col-md-12"
            variant="danger"
            dismissible
            >Datafile Ownership Transfer Failed</b-alert
          >
        </div>
      </div>
      <!-- All Companies -->
      <div class="row mt-3">
        <div class="col-md-12">
          <label> Select Company: </label>
          <multiselect
            v-model="selectedCompany"
            :options="allCompanies"
            :placeholder="
              $t('uploadProcessStep2.form.general.selectColumnType.placeholder')
            "
            track-by="name"
            :searchable="true"
            label="name"
            @input="selectCompany"
          >
          </multiselect>
        </div>
      </div>
      <!-- All user of Selected Company -->
      <div class="row mt-3" v-if="this.selectedCompany">
        <div class="col-md-12">
          <label> Select New Owner: </label>
          <multiselect
            v-if="!isFetchingComapanyUsers"
            v-model="selectedUser"
            :options="allUsersOfSelectedCompany"
            :placeholder="
              $t('uploadProcessStep2.form.general.selectColumnType.placeholder')
            "
            track-by="id"
            :searchable="true"
            label="name"
            :custom-label="nameWithEmail"
            :show-labels="false"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.name.first }} {{ option.name.last }}
              <span v-if="option.name.first || option.name.last"> - </span>
              {{ option.email }}
            </template>
            <template slot="option" slot-scope="{ option }">
              {{ option.name.first }} {{ option.name.last }}
              <span v-if="option.name.first || option.name.last"> - </span>
              {{ option.email }}
            </template>
          </multiselect>
          <i
            class="bx bx-loader-circle bx-spin ml-2"
            v-if="isFetchingComapanyUsers"
          ></i>
        </div>
      </div>
      <!-- Change Wallet & Transactions -->
      <div class="row mt-3" v-if="this.selectedCompany">
        <div class="col-md-12">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="CleanDatafileCheckbox"
              v-model="changeWalletAndTransactions"
            />
            <label class="custom-control-label" for="CleanDatafileCheckbox">
              <span class="font-weight-normal">
                Change Wallets & Transactions
              </span>
            </label>
          </div>
        </div>
      </div>
      <!-- Action Buttons -->
      <div class="row mt-5 mb-2">
        <div class="col-md-12">
          <b-button variant="warning" @click="clickChangeOwnership">
            Change Ownership
          </b-button>
          <b-button variant="light" @click="clickCloseButton" class="ml-2">{{
            $t("datafiles.deleteDatafileModal.cancelButton")
          }}</b-button>
        </div>
      </div>
    </div>
    <!-- Success -->
    <div
      class="row mt-3 text-center"
      v-if="transferDatafileOwnershipSuccess && !isLoading"
    >
      <div class="col-md-12">
        <h5 class="text-success">Successfully Transfer Datafile</h5>
        <p>Datafile owenership is successfully transfer to new owner</p>
      </div>
      <div class="col-md-12 mt-3">
        <b-button variant="light" @click="clickSuccessCloseButton">
          {{ $t("datafiles.deleteDatafileModal.cancelButton") }}
        </b-button>
      </div>
    </div>
    <!-- Loading -->
    <div class="row justify-content-center" v-if="isLoading">
      <div class="col-6 text-center pt-5 pb-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>
  </b-modal>
</template>
