<script>
import DatafileResultService from "@/api/services/datafileResult.service";
export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    datafileObject: Object
  },
  data() {
    return {
      datafile: null,
      datafileDeleteSuccess: 0,
      datafileDeleteFailed: 0,
      isLoading: false,
      dismissSecs: 3,
      dismissCountDown: null
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  mounted() {
    this.datafile = this.datafileObject;
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    async transferDatafile() {
      try {
        const response = await DatafileResultService.transferDatafileOwnershipToRedem(
          this.datafile.id
        );

        if (response) {
          this.isLoading = false;
          this.datafileDeleteSuccess = this.dismissSecs;
          this.$emit("reload");
        }
      } catch (error) {
        console.log("transferDatafile: ", error.message);
        this.datafileDeleteFailed = this.dismissSecs;
        this.isLoading = false;
      }
    },
    clickCloseButton() {
      this.$emit("reload");
    },
    async clickRemoveDataset() {
      this.isLoading = true;
      await this.transferDatafile();
    }
  }
};
</script>

<template>
  <!-- Delete Dataset Model -->
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <div class="mt-3 mb-3 text-center" v-if="!isLoading">
      <b-alert
        :show="datafileDeleteSuccess"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
        class="col-md-12"
        variant="success"
        dismissible
        >{{ $t("datafiles.deleteDatafileModal.successMsg") }}</b-alert
      >
      <b-alert
        :show="datafileDeleteFailed"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
        class="col-md-12"
        variant="danger"
        dismissible
        >{{ $t("datafiles.deleteDatafileModal.errorMsg") }}</b-alert
      >
      <div class="col-md-12 mb-2">
        <img
          src="@/assets/images/delete-datafile.png"
          alt
          class="img-fluid mb-4 mt-3"
          style="width: 80px"
        />
        <h5>{{ $t("datafiles.deleteDatafileModal.title") }}</h5>
      </div>
      <div class="col-md-12 mb-4">
        <p>
          <strong>{{ datafile.name }}</strong>
        </p>
      </div>
      <div class="col-md-12">
        <p>
          {{ $t("datafiles.deleteDatafileModal.text1") }}
          <br />
          {{ $t("datafiles.deleteDatafileModal.text2") }}
        </p>
      </div>
      <div class="col-md-12 mt-3">
        <b-button variant="danger" @click="clickRemoveDataset">{{
          $t("datafiles.deleteDatafileModal.deleteButton")
        }}</b-button>
        <b-button variant="light" @click="clickCloseButton" class="ml-2">{{
          $t("datafiles.deleteDatafileModal.cancelButton")
        }}</b-button>
      </div>
    </div>

    <div class="row justify-content-center" v-else>
      <div class="col-6 text-center pt-5 pb-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>
  </b-modal>
</template>
