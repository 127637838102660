var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "mt-3 mb-3 text-center" },
            [
              _c(
                "b-alert",
                {
                  staticClass: "col-md-12",
                  attrs: {
                    show: _vm.datafileDeleteSuccess,
                    variant: "success",
                    dismissible: "",
                  },
                  on: {
                    dismissed: function ($event) {
                      _vm.dismissCountDown = 0
                    },
                    "dismiss-count-down": _vm.countDownChanged,
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("datafiles.deleteDatafileModal.successMsg"))
                  ),
                ]
              ),
              _c(
                "b-alert",
                {
                  staticClass: "col-md-12",
                  attrs: {
                    show: _vm.datafileDeleteFailed,
                    variant: "danger",
                    dismissible: "",
                  },
                  on: {
                    dismissed: function ($event) {
                      _vm.dismissCountDown = 0
                    },
                    "dismiss-count-down": _vm.countDownChanged,
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("datafiles.deleteDatafileModal.errorMsg"))
                  ),
                ]
              ),
              _c("div", { staticClass: "col-md-12 mb-2" }, [
                _c("img", {
                  staticClass: "img-fluid mb-4 mt-3",
                  staticStyle: { width: "80px" },
                  attrs: {
                    src: require("@/assets/images/delete-datafile.png"),
                    alt: "",
                  },
                }),
                _c("h5", [
                  _vm._v(_vm._s(_vm.$t("datafiles.deleteDatafileModal.title"))),
                ]),
              ]),
              _c("div", { staticClass: "col-md-12 mb-4" }, [
                _c("p", [_c("strong", [_vm._v(_vm._s(_vm.datafile.name))])]),
              ]),
              _c("div", { staticClass: "col-md-12" }, [
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("datafiles.deleteDatafileModal.text1")) +
                      " "
                  ),
                  _c("br"),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("datafiles.deleteDatafileModal.text2")) +
                      " "
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "col-md-12 mt-3" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "danger" },
                      on: { click: _vm.clickDeleteDataset },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("datafiles.deleteDatafileModal.deleteButton")
                        )
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-2",
                      attrs: { variant: "light" },
                      on: { click: _vm.clickCloseButton },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("datafiles.deleteDatafileModal.cancelButton")
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("div", { staticClass: "row justify-content-center" }, [
            _c(
              "div",
              { staticClass: "col-6 text-center pt-5 pb-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }