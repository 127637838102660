var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "card-title col-8" }, [
          _c(
            "div",
            {
              staticClass: "text-limit",
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  _vm.project.isLiveCleaning !== _vm.liveCleaningStatus.draft
                    ? _vm.clickViewResults(_vm.project)
                    : _vm.clickViewInstructions(_vm.project)
                },
              },
            },
            [
              _vm.project.isLiveCleaning &&
              _vm.project.isLiveCleaning === _vm.liveCleaningStatus.running
                ? _c("i", {
                    staticClass: "bx bxs-circle bx-flashing text-success mr-1",
                  })
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.truncatedText(_vm.project.name)) + " "),
            ]
          ),
          _c("small", [
            _vm._v(" " + _vm._s(_vm.project.createdDate.slice(0, 10)) + " "),
          ]),
        ]),
        _c("div", { staticClass: "text-right col-4" }, [
          _vm.project.certificateLevel
            ? _c(
                "div",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-badge",
                    {
                      staticStyle: { "font-size": "12px" },
                      attrs: { variant: "gold", pill: "" },
                    },
                    [_vm._v(" " + _vm._s(_vm.project.certificateLevel) + " ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.project.isDeleted &&
          this.loggedInUser.accessLevel === _vm.accessLevelEnum.SUPER_ADMIN
            ? _c(
                "div",
                [
                  _c(
                    "b-badge",
                    {
                      staticStyle: { "font-size": "12px" },
                      attrs: { variant: "danger" },
                    },
                    [_vm._v(" Deleted ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c(
              "b-badge",
              {
                staticStyle: { "font-size": "12px" },
                attrs: { variant: "light" },
              },
              [
                _c("i", {
                  class: {
                    "bx bx-code-alt": `${_vm.project.importType}` === "LIVE",
                    "bx bx-file": `${_vm.project.importType}` === "XLXS",
                  },
                }),
                _c("span", [
                  _vm._v(" " + _vm._s(_vm.project.importType) + " Import "),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "card-text row mt-3" }, [
        _c(
          "div",
          { staticClass: "col-8" },
          [
            _vm.project.isLiveCleaning !== _vm.liveCleaningStatus.draft
              ? _c(
                  "b-button",
                  {
                    staticClass: "btn-sm",
                    attrs: { variant: "success" },
                    on: {
                      click: function ($event) {
                        return _vm.clickViewResults(_vm.project)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("datafiles.viewMoreButton")))]
                )
              : _vm._e(),
            _vm.project.isLiveCleaning === _vm.liveCleaningStatus.draft
              ? _c(
                  "b-button",
                  {
                    staticClass: "btn-sm",
                    attrs: { variant: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.clickViewInstructions(_vm.project)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("datafiles.viewInstructionsButton")))]
                )
              : _vm._e(),
            _vm.project.isLiveCleaning &&
            _vm.project.isLiveCleaning === _vm.liveCleaningStatus.running
              ? _c(
                  "b-button",
                  {
                    staticClass: "btn-sm ml-2",
                    attrs: { variant: "danger" },
                    on: {
                      click: function ($event) {
                        return _vm.clickChangeDatafileStatus(_vm.project)
                      },
                    },
                  },
                  [_c("i", { staticClass: "bx bx-stop" })]
                )
              : _vm._e(),
            this.loggedInUser.accessLevel !== _vm.accessLevelEnum.EMPLOYEE ||
            (this.loggedInUser.accessLevel === _vm.accessLevelEnum.EMPLOYEE &&
              _vm.project.isLiveCleaning)
              ? _c(
                  "b-dropdown",
                  {
                    staticClass: "ml-2",
                    attrs: { size: "sm", variant: "light", dropright: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "button-content",
                          fn: function () {
                            return [
                              _c("i", {
                                staticClass: "bx bx-dots-vertical-rounded",
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2127224613
                    ),
                  },
                  [
                    _vm.project.isLiveCleaning &&
                    _vm.project.isLiveCleaning === _vm.liveCleaningStatus.stop
                      ? _c(
                          "b-dropdown-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.clickChangeDatafileStatus(
                                  _vm.project
                                )
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "bx bx-sync mr-1" }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("datafiles.reopen")) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    this.loggedInUser.accessLevel ===
                      _vm.accessLevelEnum.SUPER_ADMIN &&
                    (_vm.project.isLiveCleaning ===
                      _vm.liveCleaningStatus.stop ||
                      !_vm.project.isLiveCleaning)
                      ? _c(
                          "b-dropdown-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.clickResetDatafile(_vm.project)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "bx bx-reset mr-1" }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("datafiles.reupload")) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    this.loggedInUser.accessLevel ===
                    _vm.accessLevelEnum.SUPER_ADMIN
                      ? _c(
                          "b-dropdown-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.clickDatafileOwnershipChange(
                                  _vm.project
                                )
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "bx bx-transfer-alt mr-1" }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("datafiles.changeOwnership")) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    this.loggedInUser.accessLevel !==
                    _vm.accessLevelEnum.EMPLOYEE
                      ? _c(
                          "b-dropdown-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.clickDeleteDatafile(_vm.project)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "bx bx-trash mr-1" }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("datafiles.delete")) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "col-4 text-right" }, [
          _c(
            "span",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.left.v-light",
                  modifiers: { hover: true, left: true, "v-light": true },
                },
              ],
              attrs: {
                title:
                  _vm.project.owner.name.first +
                  " " +
                  _vm.project.owner.name.last,
              },
            },
            [
              _vm.project.owner.image
                ? _c("img", {
                    staticStyle: { "border-radius": "50%" },
                    attrs: { src: _vm.project.owner.image, height: "20" },
                  })
                : _c("img", {
                    staticStyle: { "border-radius": "50%" },
                    attrs: {
                      src: "https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/default-avatar.png",
                      height: "20",
                    },
                  }),
            ]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.left.v-light",
                  modifiers: { hover: true, left: true, "v-light": true },
                },
              ],
              staticClass: "ml-2",
              attrs: { title: _vm.project.company.name },
            },
            [
              _vm.project.company.image
                ? _c("img", {
                    attrs: { src: _vm.project.company.image, height: "20" },
                  })
                : _c("img", {
                    staticStyle: { "border-radius": "50%" },
                    attrs: {
                      src: "https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/dafault-company.jpg",
                      height: "20",
                    },
                  }),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }